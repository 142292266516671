import throttle from 'lodash/throttle';
import {DeviceMatcher} from '../../../utilities/sink';

class HeaderComponent {
	init() {
		let sticky = document.querySelector('.header-main');
		

		if(!sticky){ return; }

		if (!DeviceMatcher.isSmallSizeDevice()) {
			
			window.addEventListener('scroll', throttle(() => {
				let scroll = window.pageYOffset;
				if (scroll >= 10) sticky.classList.add('header-main--is-sticky');
				else sticky.classList.remove('header-main--is-sticky');
			}, 50))

		}else{
			window.addEventListener('scroll', throttle(() => {
				let scroll = window.pageYOffset;
				if (scroll >= 120) sticky.classList.add('header-main--is-scrolling');
				else sticky.classList.remove('header-main--is-scrolling');
			}, 50));
		}
	}
}

export default HeaderComponent;