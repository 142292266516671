import merge from 'lodash/merge';

/**
 *
 * @type {{init: () => any}}
 */
export const Polyfills = {
	/**
	 *
	 */
    detect: () => {

        if (DeviceMatcher.isBullshitBrowser()) {
            document.querySelector('html').classList.add('ie');
        }
    },
	/**
	 *
	 */
    init: () => {
        Polyfills.patchSmoothScrolling();
    },

    patchSmoothScrolling: () => {

        if (window.location.hash && window.location.hash.includes('scroll-to-')) {

            const elementId = window.location.hash.substring('scroll-to-'.length + 1);

            //$('html, body').animate({
            //	scrollTop: ($('#' + elementId).offset().top - 170) + 'px'
            //}, 1000, 'swing');
        }
    }
};

/**
 *
 * @type {{read: ((key: string) => any); write: ((key: string, value: string, days?: number) => any)}}
 */
export const Cookie = {
	/**
	 * read cookie by key
	 *
	 * @param {string} key
	 */
    get: (key: string) => {
        return ('; ' + document.cookie)
            .split('; ' + key + '=')
            .pop()
            .split(';')
            .shift();
    },
	/**
	 * write cookie
	 *
	 * @param {string} key
	 * @param {string} value
	 * @param {number} days
	 */
    set: (key: string, value: string, days: number = 14) => {
        let d = new Date();
        d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = key + "=" + value + ";" + expires + ";path=/";
    }
};

/**
 *
 * @type {{sendEvent: (category, label?: any, action?: any, value?: any) => void}}
 */
export const GTM = {

	/**
	 * send event to google tag manager
	 *
	 * @param category
	 * @param action
	 * @param {any} label
	 * @param {any} value
	 * @param {boolean} nonInteraction
	 */
    sendEvent: (category, action, label = undefined, value = undefined, nonInteraction = false) => {

        //console.log(category, action, label, value, nonInteraction);

        (window as any).dataLayer = (window as any).dataLayer || [];

        (window as any).dataLayer.push({
            'event': 'google-analytics-event',
            'eventCategory': category,
            'eventAction': action,
            'eventLabel': label,
            'eventValue': value,
            'nonInteraction': nonInteraction
        });
    }
}

/**
 * get variable from url hash #
 * @param {string} name variable name
 */
export const getLocationHashVariable = (name: string) => {
    const query = window.location.hash.substring(1);
    const vars = query.split('&');

    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');

        if (decodeURIComponent(pair[0]) == name) {
            return decodeURIComponent(pair[1]);
        }
    }

    return '';
}

/**
 * animated scrolling
 *
 * @param {number} scrollTargetY
 * @param {number} speed pixels scrolled per second
 * @param {string} easing easing equation to use
 */
export const scrollToY = (scrollTargetY: number, speed: number = 2000, easing: string = 'easeOutSine') => {

    let scrollY = window.scrollY || document.documentElement.scrollTop,
        currentTime = 0;

    // min 100ms, max 2000ms
    let time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, 2));

    // https://github.com/danro/easing-js/blob/master/easing.js
    let easingEquations = {
        easeOutSine: (pos) => {
            return Math.sin(pos * (Math.PI / 2));
        },
        easeInOutSine: (pos) => {
            return (-0.5 * (Math.cos(Math.PI * pos) - 1));
        },
        easeInOutQuint: (pos) => {
            if ((pos /= 0.5) < 1) {
                return 0.5 * Math.pow(pos, 5);
            }
            return 0.5 * (Math.pow((pos - 2), 5) + 2);
        }
    };

    // animation loop
    const tick = () => {
        currentTime += 1 / 60;

        let p = currentTime / time;
        let t = easingEquations[easing](p);

        if (p < 1) {
            window.requestAnimationFrame(tick);
            window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t));
        } else {
            window.scrollTo(0, scrollTargetY);
        }
    };

    // call it once to get started
    tick();
};

/**
 * animated scrolling
 *
 * @param {number} scrollTargetY
 * @param {number} speed pixels scrolled per second
 * @param {string} easing easing equation to use
 */
export const scrollToYInsideElement = (element: HTMLElement, scrollTargetY: number, speed: number = 2000, easing: string = 'easeOutSine') => {

    let scrollY = element.scrollTop,
        currentTime = 0;

    // min 100ms, max 2000ms
    let time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, 2));

    // https://github.com/danro/easing-js/blob/master/easing.js
    let easingEquations = {
        easeOutSine: (pos) => {
            return Math.sin(pos * (Math.PI / 2));
        },
        easeInOutSine: (pos) => {
            return (-0.5 * (Math.cos(Math.PI * pos) - 1));
        },
        easeInOutQuint: (pos) => {
            if ((pos /= 0.5) < 1) {
                return 0.5 * Math.pow(pos, 5);
            }
            return 0.5 * (Math.pow((pos - 2), 5) + 2);
        }
    };

    // animation loop
    const tick = () => {
        currentTime += 1 / 60;

        let p = currentTime / time;
        let t = easingEquations[easing](p);

        if (p < 1) {
            window.requestAnimationFrame(tick);
            element.scrollTop = scrollY + ((scrollTargetY - scrollY) * t);
        } else {
            element.scrollTop = scrollTargetY;
        }
    };

    // call it once to get started
    tick();
};

/**
 * match device configuration
 *
 * @type {{isSmallSizeDevice: (() => boolean); isMediumSizeDevice: (() => boolean); isLargeSizeDevice: (() => boolean); isMobileDevice: (() => boolean); isInternetExplorer: (() => boolean)}}
 */
export const DeviceMatcher = {

    isSmallSizeDevice: () => {
        return window.matchMedia('(max-width: 62rem)').matches;
    },

    isMediumSizeDevice: () => {
        return window.matchMedia('(min-width: 62rem and max-width: 75rem)').matches;
    },

    isLargeSizeDevice: () => {
        return window.matchMedia('(min-width: 75rem)').matches;
    },

    isDeviceLargerAs: (measure: string) => {
        return window.matchMedia('(min-width: ' + measure + ')').matches;
    },

    isMobileDevice: () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },

    isInternetExplorer: () => {
        return /Trident/i.test(navigator.userAgent);
    },

    isEdge: () => {
        return /Edge/i.test(navigator.userAgent);
    },

    isBullshitBrowser: () => {
        return DeviceMatcher.isEdge() || DeviceMatcher.isInternetExplorer();
    }
};

/**
 *
 * @type {{getOffsetRelativeToDocument: (element: HTMLElement) => {left: number; top: number}; getOffsetRelativeToViewport: (element: HTMLElement) => {left: number; top: number}}}
 */
export const ElementInspector = {

	/**
	 *
	 * @param {HTMLElement} element
	 * @returns {{left: number; top: number}}
	 */
    getOffsetRelativeToDocument: (element: HTMLElement) => {
        let rect = element.getBoundingClientRect();
        return {
            left: Math.floor(rect.left + window.scrollX),
            top: Math.floor(rect.top + window.scrollY)
        }
    },

	/**
	 *
	 * @param {HTMLElement} element
	 * @returns {{left: number; top: number}}
	 */
    getOffsetRelativeToViewport: (element: HTMLElement) => {
        let rect = element.getBoundingClientRect();
        return {
            left: Math.floor(rect.left),
            top: Math.floor(rect.top)
        }
    },
};

/**
 * read <script data-translation>JSON</script> tags
 */
class TranslationStorage {
    protected translations = {};

	/**
	 *
	 */
    constructor() {
        let dictionaries = document.querySelectorAll("[data-translation]");

        for (let dictionary of [].slice.call(dictionaries)) {
            let translations = JSON.parse(dictionary.innerHTML);
            merge(this.translations, translations);
        }
    }

	/**
	 *
	 * @param {string} key
	 * @returns {any}
	 */
    get(key: string) {
        if (this.translations.hasOwnProperty(key)) {
            return this.translations[key];
        }

        return 'PLEASE TRANSLATE ' + key;
    }
}

let translationStorage = new TranslationStorage();

/**
 *
 * @param key
 * @returns {any | string}
 */
export const translate = (key) => {
    return decodeHtmlEntities(translationStorage.get(key));
};

export const decodeHtmlEntities = (input) => {
    let output = document.createElement("textarea");
    output.innerHTML = input;
    return output.value;
};