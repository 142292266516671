
/**
 *
 */
export abstract class UiWidget {
	selector: string;
	elements: any[];

	/**
	 *
	 * @param {string} selector
	 */
	constructor(selector: string){
		this.selector = selector;
		this.elements = Array.prototype.slice.call(document.querySelectorAll(this.selector));

		this.bindEventListeners();
	}

	bindEventListeners(): void{

	}

}