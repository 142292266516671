import Glide, {
    Autoplay,
    Controls,
    Breakpoints,
    Swipe
} from '@glidejs/glide/dist/glide.modular.esm';

import Rellax from 'rellax';
import 'intersection-observer';
import scrollama from 'scrollama';
import {
    CountUp
} from 'countup.js';

import HeaderComponent from './modules/base/components/header';
import CeModalComponent from './modules/base/components/ce-modal';
import CookieNoticeComponent from './modules/base/components/cookie-notice';
import CeAccordionComponent from './modules/base/components/ce-accordion';

import {
    ToggleClassWidget
} from './modules/base/widgets/toggle-class';


let widgets = [new ToggleClassWidget('[data-init-toggle-class]')];

const components = [
    new HeaderComponent(),
    new CeAccordionComponent(),
    new CookieNoticeComponent(),
    new CeModalComponent(),
];

components.map((component) => component.init());

const w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    viewportWidth = w.innerWidth || e.clientWidth || g.clientWidth;


const scroller = scrollama();

// setup the instance, pass callback functions
scroller
    .setup({
        step: '.scrollama',
        offset: .8
    })
    .onStepEnter(response => {
        response.element.classList.add('in-view');

        // load lazy images
        const lazyImages = response.element.querySelectorAll('.lazy');
        Array.prototype.forEach.call(lazyImages, (el) => {
            el.src = el.dataset.src
        });

        // start counting up elements
        const countUpElements = response.element.querySelectorAll('.a-ctu');
        Array.prototype.forEach.call(countUpElements, (el) => {
            let countUp = new CountUp(el, parseInt(el.dataset.target), {
                duration: 3,
                separator: el.dataset.separator ? el.dataset.separator : ''
            });
            countUp.start();
        });
    });

// setup resize event
window.addEventListener('resize', scroller.resize);

if (viewportWidth > 800) {
    new Rellax('.rellax', {
        center: true
    });
} else {
    new Rellax('.rellax-mobile');
}

if (document.querySelector('.ce-fair-teaser .glide')) {
    new Glide('.ce-fair-teaser .glide', {
        type: 'slider',
        gap: 20,
        animationDuration: 1000,
        perView: 3,
        breakpoints: {
            700: {
                perView: 1
            },
            1000: {
                perView: 2
            },
        }
    }).mount({
        Controls,
        Breakpoints,
        Swipe
    });
}

// async images
(() => {
    // Page is loaded
    const objects = document.querySelectorAll('.async');
    Array.from(objects).map((item) => {
        // Start loading image
        const img = new Image();
        img.src = item.dataset.hrSrc;
        // Once image is loaded replace the src of the HTML element
        img.onload = () => {
            item.classList.remove('async');
            return item.nodeName === 'IMG' ?
                item.src = item.dataset.hrSrc :
                item.style.backgroundImage = `url(${item.dataset.src})`;
        };
    });
})();