
/**
 *
 */
class CeAccordionComponent {
	/**
	 *
	 */
	init() {
		this.initEventListeners();
	}

	/**
	 *
	 */
	initEventListeners() {
        Array.from(document.querySelectorAll('.ce-accordion__headline')).map(el => el.addEventListener('click', e => this.onClick(e)));
	}

	/**
	 *
	 * @param e
	 */
	onClick(e: Event) {
        e.preventDefault();

		let headline = e.currentTarget;
		let content = document.querySelector('#' + headline.dataset.open);

        if(headline.classList.contains('opened')){
            headline.classList.remove('opened');
            content.classList.remove('opened');
        }else{
            headline.classList.add('opened');
            content.classList.add('opened');
        }
	}
}

export default CeAccordionComponent;