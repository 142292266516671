import { DeviceMatcher, GTM } from "../../../utilities/sink";

class CeModalComponent {

    private modals: Array<HTMLElement> | null = null;

    private body: HTMLBodyElement | null = null;

    init() {
        this.initEventListeners();
        this.initAutoStartModals();
        this.initNodes();
    }

    initNodes() {
        this.modals = Array.prototype.slice.call(document.querySelectorAll('.ce-modal'));
        this.body = document.querySelector('body');
    }

    initAutoStartModals() {
        let autostartModals = Array.prototype.slice.call(document.querySelectorAll('.ce-modal--diplay-mode-2'));

        if (autostartModals.length > 0) {
            for (let modal of autostartModals) {
                window.setTimeout(() => {
                    this.openModalById(modal.dataset.modal);
                }, modal.dataset.modalTiming * 1000);
            }
        }
    }

	/**
	 *
	 */
    initEventListeners() {
        // click on modal open triggers
        const openTriggers = Array.prototype.slice.call(document.querySelectorAll('[data-modal-trigger-open]'));
        const closeTriggers = Array.prototype.slice.call(document.querySelectorAll('[data-modal-trigger-close]'));

        openTriggers.map((el) => {
            el.addEventListener('click', (e: Event) => this.openModal(e));
        });

        closeTriggers.map((el) => {
            el.addEventListener('click', (e: Event) => this.closeModal(e));
        });

        // close all modals when user hits escape key
        document.addEventListener('keydown', (e) => {
            if (e.keyCode == 27 && this.body && this.modals) {
                this.body.classList.remove("body--modal-active");
                this.modals.map((el) => el.classList.remove('ce-modal--active'));
                this.stopYoutubePlayer();
            }
        });
    }

    openModal(e: Event) {

        if (e) {
            let modalId = e.target.dataset.modalTriggerOpen;

            this.openModalById(modalId);

            e.preventDefault();
        }
    }

    openModalById(id: number) {
        let modal: HTMLElement | null = document.querySelector('[data-modal="' + id + '"]');

        if (modal && modal.dataset.gtm !== undefined) {
            GTM.sendEvent('Modal', modal.dataset.gtmAction, modal.dataset.gtmLabel);
        }

        // add css classes
        this.body?.classList.add("body--modal-active");
        modal.classList.add('ce-modal--active');

        // set top offset relativ to scroll position
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // add space for close icon
        if (DeviceMatcher.isSmallSizeDevice()) {
            scrollTop += 40;
        }

        modal.querySelector('.ce-modal__instance').style.top = (scrollTop) + "px";
    }

	/**
	 *
	 * @param e
	 */
    closeModal(e: Event) {
        let modalId = e.target.dataset.modalTriggerClose;

        this.closeModalById(modalId);

        e.preventDefault();
    }

	/**
	 *
	 * @param id
	 */
    closeModalById(id) {
        let modal: HTMLElement | null = document.querySelector('[data-modal="' + id + '"]');

        // add css classes
        this.body?.classList.remove("body--modal-active");
        modal.classList.remove('ce-modal--active');
    }

	/**
	 * 
	 * @param string id
	 */
    isOpened(id: string) {
        const modal: HTMLElement | null = document.querySelector('[data-modal="' + id + '"]');

        return modal.classList.contains('ce-modal--active');
    }
}

export default CeModalComponent;