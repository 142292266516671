import {UiWidget} from "./abstract";

/**
 * @example data-init-toggle-class='[{"target":"#navigation", "class": "nav-main--active"},{"target":"body", "class": "nav-main--active"}]'
 */
export class ToggleClassWidget extends UiWidget{

	/**
	 *
	 * @param selector
	 */
	constructor(selector: string){
		super(selector);
	}

	/**
	 *
	 */
	bindEventListeners(): void{

		this.elements.map((el: Element) => {
			el.addEventListener('click', (e: Event) => this.handleClickEvent(e));
		});
	}

	/**
	 *
	 * @param {Event} e
	 */
	handleClickEvent(e: Event){

		let eventTarget: HTMLElement = (e.currentTarget as HTMLElement);

		// inline attribute options
		let options: ToggleClassWidgetOptions|Array<ToggleClassWidgetOptions> = JSON.parse(eventTarget.dataset.initToggleClass);

		for(let item of options){
			let target = (document.querySelector(item.target) as HTMLElement);
			if(target.classList.contains(item.class)){
				target.classList.remove(item.class);
			}else{
				target.classList.add(item.class);
			}
		}
	}
}

interface ToggleClassWidgetOptions {
	/**
	 * self|parent|child or selector
	 */
	target: string;

	/**
	 * css class
	 */
	class: string;
}