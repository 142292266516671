import { Cookie } from '../../../utilities/sink';

declare var _etracker: any;

const COOKIE_NAME = 'cookie_content';
const COOKIE_VALUE_ACCEPTED = 'accepted';
const COOKIE_VALUE_DECLINED = 'declined';

class CookieNoticeComponent {

    /**
     *
     */
    private element: HTMLElement;
    private acceptButton: HTMLButtonElement;
    private declineButton: HTMLButtonElement;
    private openDialogButtons: Array<HTMLElement>;
    private scripts: Array<HTMLScriptElement>;

    /**
     *
     */
    constructor() {
        this.element = (document.getElementById('cookie-notice') as HTMLDivElement)
        this.acceptButton = (this.element.querySelector('.cookie-notice__buttons__accept') as HTMLButtonElement);
        this.declineButton = (this.element.querySelector('.cookie-notice__buttons__decline') as HTMLButtonElement);
        this.openDialogButtons = Array.from((document.querySelectorAll('.cookie-notice__open')));
        this.scripts = Array.from(document.querySelectorAll('[data-cookie-manager]'));
    }

    /**
     *
     */
    init() {
        if (this.element) {
            if (!CookieNoticeComponent.isCookieSet()) {
                this.show();
            }

            if (Cookie.get(COOKIE_NAME) == COOKIE_VALUE_ACCEPTED) {
                this.enableTrackers();
            }

            this.initEventListener();
        }
    }

    /**
     * init click event
     */
    initEventListener() {
        this.acceptButton.addEventListener('click', (e) => this.handleAcceptClick(e));
        this.declineButton.addEventListener('click', (e) => this.handleDeclineClick(e));
        this.openDialogButtons.map((el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();

                this.show();
            });
        })
    }

    /**
     * handle click event
     *
     * @param {Event} e
     */
    handleAcceptClick(e: Event) {
        e.preventDefault();

        // save state
        Cookie.set(COOKIE_NAME, COOKIE_VALUE_ACCEPTED);

        // hide notice
        this.hide();

        // enable etracker cookie tracking
        this.enableTrackers();
    }

    /**
     * handle click event
     *
     * @param {Event} e
     */
    handleDeclineClick(e: Event) {
        e.preventDefault();

        // save state
        Cookie.set(COOKIE_NAME, COOKIE_VALUE_DECLINED);

        // hide notice
        this.hide();
    }

    /**
     * 
     */
    enableTrackers() {
        const head: any = document.querySelector('head');
        this.scripts.map((script) => {
            let newScript: any = script.cloneNode(true);
            newScript.type = 'text/javascript';

            head.append(newScript);
        });
    }


    /**
     * show element
     */
    show() {
        this.element.classList.add('active');
    }

    /**
     * hide element
     */
    hide() {
        this.element.classList.remove('active');
    }

    /**
     * was cookie notice accepted?
     *
     * @returns {boolean}
     */
    static isCookieSet() {
        return Cookie.get(COOKIE_NAME) == COOKIE_VALUE_ACCEPTED || Cookie.get(COOKIE_NAME) == COOKIE_VALUE_DECLINED;
    }
}

export default CookieNoticeComponent;